.navbar-items ul li a {
  writing-mode: vertical-rl;
  text-orientation: sideways;
  transform: rotate(180deg);
  transition: transform 500ms ease-in-out 25ms;
}
.navbar-items ul li:hover a {
  transform: rotate(180deg) translateY(30px);
}
.navbar-items ul li:hover {
  background-color: #424242;
}

.mobile-nav-hover a,
.mobile-nav-hover span {
  transition: transform 500ms ease-in-out 25ms;
}
.mobile-nav-hover:hover {
  background-color: #424242;
}
.mobile-nav-hover:hover a,
.mobile-nav-hover:hover span {
  transform: translateX(30px);
}
.react-multi-carousel-dot-list {
  position: absolute;
  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  justify-content: flex-start !important;
  margin: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.react-multi-carousel-dot--active button {
  background: #fff !important;
  height: 16px !important;
  width: 16px !important;
}

.control-dots {
  text-align: start !important;
  margin: 0px !important;
}

.control-dots li {
  width: 12px !important;
  height: 12px !important;
}

.connector {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -10px;
  background-color: #202020;
  border: #773b35 solid 1px;
  border-radius: 100%;
}

.connector-line {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -150px;
  height: 1px;
  width: 150px;
  background-color: #773b35;
}

.background-use-cases {
  background-image: url('assets/images/Viz.png');
  background-position: right 450px;
}

.dot.selected {
  background-color: #BF3D30 !important;
}

.control-dots {
  justify-content: end;
  display: flex;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: grey;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 7px;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext {
  bottom: -2px;
  margin-left: 10px;
}
@media only screen and (max-width: 1025px) {
  .connector {
    top: -10px;
    transform: translateX(-50%);
    left: 50%;
  }
  .connector-line {
    top: 100%;
    transform: none;
    right: 50%;
    height: 150px;
    width: 1px;
  }
  .background-use-cases{
    background-image: none;
  }
}
